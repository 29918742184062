import {
  Button,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IconButtonOutlined } from "../components/IconButtonOutlined";
import IcClose from "../assets/icons/IcClose";
import { Trade } from "../types/Trade";
import { TradingPlan } from "../types/TradingPlan";
import IcNewsFolder from "../assets/icons/IcNewsFolder";
import IcWhitePaper from "../assets/icons/white-paper";
import IcChecklist from "../assets/icons/check-list";
import IcNotificationCounter from "../assets/icons/IcNotificationCounter";
import IcCalendar from "../assets/icons/IcCalendar";
import { SYMBOLS } from "../assets/static";
import { Add } from "@mui/icons-material";
import TradingPlanEditor from "../components/TradingPlanEditor";
import {
  RtmGetLinkPreview,
  RtmGetTrade,
  RtmUpdateTrade,
} from "../core/rtm/user";
import IcAttachment from "../assets/icons/attachment";

function DialogSidebarButton(props: {
  selected?: boolean;
  icon?: any;
  onClick?: () => void;
}) {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        p: "12px",
        borderRadius: "8px",
        background: props.selected ? "#2563EB1F" : "",
      }}
    >
      <SvgIcon
        htmlColor={props.selected ? "#2563EB" : "#6B7280"}
        sx={{ height: "24px", width: "24px", p: 0 }}
      >
        {props.icon}
      </SvgIcon>
    </IconButton>
  );
}

function PaperInput(props: {
  value?: string;
  onChange?: (text: string) => void;
}) {
  return (
    <Paper
      elevation={2}
      sx={{
        px: "12px",
        py: "10px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={"10px"}>
        <IcAttachment />
        <input
          value={props.value}
          onChange={(e) => props.onChange?.(e.currentTarget.value)}
          placeholder="Insert TradingView Link"
          style={{
            outline: "none",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
            width: "100%",
          }}
        />
      </Stack>
    </Paper>
  );
}

function LinkPreview(props: {
  preview: {
    title: string;
    description: string;
    thumbnail: string;
    url: string;
  };
}) {
  return (
    <Paper elevation={2} sx={{ overflow: "hidden" }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack sx={{ p: "12px" }}>
          {/* Title  */}
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
              color: "#020617",
            }}
          >
            {props.preview.title}
          </Typography>
          {/* Link */}
          <Typography
            sx={{
              color: "#2563EB",
              fontSize: 14,
              fontWeight: 400,
              cursor: "pointer",
            }}
            onClick={() => window.open(props.preview.url, "_blank")}
          >
            {props.preview.url}
          </Typography>
        </Stack>
        <img
          style={{ height: "70px", width: "100px", objectFit: "cover" }}
          src={props.preview.thumbnail}
          alt={props.preview.title}
        />
      </Stack>
    </Paper>
  );
}

export default function TradeDetailsDialog(props: {
  onClose?: () => void;
  trade: Trade;
}) {
  const theme = useTheme();

  const [section, setSection] = useState<
    "details" | "calendar" | "activity" | "plan"
  >("details");
  const [plan, setPlan] = useState<TradingPlan>();
  const [trade, setTrade] = useState<Trade>(props.trade);
  const [busy, setBusy] = useState(false);
  const [tradingViewLinkPreview, setTradingViewLinkPreview] = useState<{
    title: string;
    description: string;
    thumbnail: string;
    url: string;
  }>();

  async function load() {
    try {
      const _trade = await RtmGetTrade(trade.id.toString());
      if (_trade.plan) {
        setPlan(_trade.plan);
      }
      setTrade(_trade);
      if (_trade.tradingViewLink) {
        const _preview = await RtmGetLinkPreview(_trade.tradingViewLink);
        if (_preview) {
          setTradingViewLinkPreview({
            ..._preview,
            url: _trade.tradingViewLink,
          });
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading historical events for this event. ", {
        variant: "error",
      });
      console.error("Error loading historical events. ", err);
    }
  }

  function KeyValuePair(props: { label: string; value: any }) {
    return (
      <Stack
        sx={{
          p: "6px",
          borderRadius: "8px",
        }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            color: "#6B7280",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {props.label}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: "#020617",
          }}
        >
          {props.value}
        </Typography>
      </Stack>
    );
  }

  async function closeAsync() {
    setBusy(true);

    await RtmUpdateTrade({ ...trade, plan: plan });

    props.onClose?.();
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      fullScreen
      onClose={closeAsync}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: "8px",
        [`& .${dialogClasses.paper}`]: {
          borderRadius: "24px",
          background: "#FFF",
          minWidth: "522px",
        },
      }}
    >
      <DialogTitle sx={{ p: "20px" }}>
        {/* Main stack as Row: With Title and Dialog Buttons (i.e. close button) */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={"18px"}
        >
          {/* The event title */}
          <Stack spacing={"4px"}>
            {/* Row 1: Folder Icon and Event Title  */}
            <Stack direction={"row"} alignItems={"center"} spacing={"10px"}>
              <SvgIcon
                sx={{
                  borderRadius: "4px",
                  p: "4px",
                  height: "36px",
                  width: "36px",
                }}
              >
                {/* Icon of the Traded Symbol  */}
                <IcNewsFolder />
              </SvgIcon>
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: theme.palette.text.primary,
                  textWrap: "wrap",
                }}
              >
                {trade.symbol}
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "6px",
                  px: "8px",
                  py: "4px",
                  background:
                    trade.type === "ORDER_TYPE_SELL"
                      ? "#FEE2E2"
                      : "rgba(41, 140, 107, 0.15)",
                }}
              >
                <Typography
                  sx={{
                    color:
                      trade.type === "ORDER_TYPE_SELL" ? "#991B1B" : "#10B981",
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {trade.volume}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/* Dialog Actions: Close, Maximize, etc.  */}
          <Stack direction={"row"} alignItems={"center"} spacing={"6px"}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: "#6B7280",
              }}
            >
              #{trade.id}
            </Typography>
            <Divider
              orientation="horizontal"
              sx={{ height: "20px", width: "1px", borderColor: "#E5E7EB" }}
            />
            <IconButtonOutlined onClick={closeAsync}>
              <IcClose />
            </IconButtonOutlined>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <Stack direction={"row"} sx={{ height: "100%" }}>
          <Stack flex={1}>
            {/* Content of Dialog  */}
            {section === "details" && (
              <Paper elevation={1} sx={{ flex: 1, border: "none", p: "20px" }}>
                <Stack spacing={"8px"}>
                  {/* Description of the Symbol */}
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#6B7280",
                    }}
                  >
                    {SYMBOLS[trade.symbol.split(".")[0]]}
                  </Typography>
                  <Stack direction={"row"} sx={{ mt: "16px" }}>
                    <Paper
                      elevation={2}
                      sx={{ flex: 1, p: "16px", maxWidth: "200px" }}
                    >
                      <Stack spacing={"4px"}>
                        <Typography
                          sx={{
                            color: "#6B7280",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        >
                          Total P&L
                        </Typography>
                        <Typography
                          sx={{
                            color: trade.pnl > 0 ? "#298C6B" : "#EF4444",
                            fontSize: 18,
                            fontWeight: 400,
                          }}
                        >
                          {trade.pnl > 0 ? "+" : "-"}$ {Math.abs(trade.pnl)}
                        </Typography>
                      </Stack>
                    </Paper>
                  </Stack>
                  <Paper
                    elevation={2}
                    sx={{
                      mt: "8px",
                      p: "12px",
                      background: "#FFF",
                      "& .MuiStack-root:nth-child(even)": {
                        background: "#F8FAFC",
                      },
                    }}
                  >
                    {/* {trade.currentPrice && (
                    <KeyValuePair
                      label={"Current price"}
                      value={trade.currentPrice}
                    />
                  )} */}
                    {trade.openPrice && (
                      <KeyValuePair
                        label={"Entry price"}
                        value={trade.openPrice}
                      />
                    )}
                    <KeyValuePair
                      label={"Exit price"}
                      value={trade.deals[0].price}
                    />
                    <KeyValuePair
                      label={"Time entry"}
                      value={moment(trade.time).format("HH:mm:ss | DD.MM.yyyy")}
                    />
                    {trade.doneTime && (
                      <KeyValuePair
                        label={"Time exit"}
                        value={moment(trade.doneTime).format(
                          "HH:mm:ss | DD.MM.yyyy"
                        )}
                      />
                    )}
                    {trade.stopLoss && (
                      <KeyValuePair
                        label={"Stop loss"}
                        value={trade.stopLoss}
                      />
                    )}
                    {trade.stopLimitPrice && (
                      <KeyValuePair
                        label={"Stop limit"}
                        value={trade.stopLimitPrice}
                      />
                    )}
                    {trade.takeProfit && (
                      <KeyValuePair
                        label={"Stop loss"}
                        value={trade.takeProfit}
                      />
                    )}
                    {/* {trade.deals[0].swap! > 0 && (
                    <KeyValuePair label={"Swap"} value={trade.deals[0].swap} />
                  )} */}
                    {/* {trade.deals[0].commission! > 0 && (
                    <KeyValuePair
                      label={"Charges"}
                      value={trade.deals[0].commission}
                    />
                  )} */}
                    {trade.comment && (
                      <KeyValuePair label={"Comment"} value={trade.comment} />
                    )}
                    {trade.reason && (
                      <KeyValuePair label={"Reason"} value={trade.reason} />
                    )}
                  </Paper>
                  {trade.deals.map((d) => (
                    <Paper
                      elevation={2}
                      sx={{
                        mt: "8px",
                        p: "12px",
                        background: "#FFF",
                        "& .MuiStack-root:nth-child(even)": {
                          background: "#F8FAFC",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#6B7280",
                        }}
                      >
                        #{d.id}
                      </Typography>
                      <KeyValuePair
                        value={moment(d.time).format("HH:mm:ss | DD.MM.yyyy")}
                        label="Time exit"
                      />
                      <KeyValuePair value={d.price} label="Price" />
                      {d.stopLoss && (
                        <KeyValuePair value={d.stopLoss} label="Stop loss" />
                      )}
                      {d.takeProfit && (
                        <KeyValuePair
                          value={d.takeProfit}
                          label="Take profit"
                        />
                      )}
                      <KeyValuePair value={d.profit} label="Profit" />
                      {d.commission && (
                        <KeyValuePair value={d.commission} label="Charges" />
                      )}
                      {d.swap! > 0 && (
                        <KeyValuePair value={d.swap} label="Swap" />
                      )}
                      {d.comment && (
                        <KeyValuePair value={d.comment} label="Charges" />
                      )}
                    </Paper>
                  ))}

                  {tradingViewLinkPreview && (
                    <LinkPreview preview={tradingViewLinkPreview} />
                  )}

                  <PaperInput
                    value={trade.tradingViewLink}
                    onChange={(t) => setTrade({ ...trade, tradingViewLink: t })}
                  />
                </Stack>
              </Paper>
            )}

            {section === "plan" && (
              <Paper elevation={1} sx={{ flex: 1, border: "none", p: "20px" }}>
                <Stack spacing={"8px"}>
                  {/* Description of the Symbol */}
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#6B7280",
                    }}
                  >
                    Trading Plan
                  </Typography>

                  {!plan && (
                    <Button
                      onClick={() => {
                        setPlan({ items: [] });
                      }}
                      size="small"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      startIcon={<Add />}
                    >
                      Add trading plan
                    </Button>
                  )}

                  {plan && (
                    <TradingPlanEditor
                      plan={plan}
                      onChange={(p) => setPlan(p)}
                    />
                  )}
                </Stack>
              </Paper>
            )}
          </Stack>
          {/* Sidebar */}
          <Stack
            spacing={"8px"}
            sx={{
              borderLeft: "1px solid #E5E7EB",
              background: "#fff",
              height: "100%",
              py: "24px",
              px: "8px",
            }}
          >
            <DialogSidebarButton
              icon={<IcWhitePaper />}
              onClick={() => setSection("details")}
              selected={section === "details"}
            />
            <DialogSidebarButton
              icon={<IcChecklist />}
              onClick={() => setSection("plan")}
              selected={section === "plan"}
            />
            <DialogSidebarButton
              icon={<IcNotificationCounter />}
              onClick={() => setSection("activity")}
              selected={section === "activity"}
            />
            <DialogSidebarButton
              icon={<IcCalendar />}
              onClick={() => setSection("calendar")}
              selected={section === "calendar"}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
