import { useState, useEffect } from "react";

// material-ui
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// third-party
import moment from "moment";

// assets
import AppstoreOutlined from "@ant-design/icons/AppstoreOutlined";
import LayoutOutlined from "@ant-design/icons/LayoutOutlined";
import LeftOutlined from "@ant-design/icons/LeftOutlined";
import OrderedListOutlined from "@ant-design/icons/OrderedListOutlined";
import PicCenterOutlined from "@ant-design/icons/PicCenterOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";
import { IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import IcArrowLeft from "../../assets/icons/arrow-left";
import IcArrowRight from "../../assets/icons/arrow-right";

// constant
const viewOptions = [
  {
    label: "Month",
    value: "dayGridMonth",
    icon: AppstoreOutlined,
  },
  {
    label: "Week",
    value: "timeGridWeek",
    icon: LayoutOutlined,
  },
];

// ==============================|| CALENDAR - TOOLBAR ||============================== //

export interface ToolbarProps {
  date: number | Date;
  view: string;
  onClickNext: () => void;
  onClickPrev: () => void;
  onClickToday: () => void;
  onChangeView: (s: string) => void;
}

export default function Toolbar({
  date,
  view,
  onClickNext,
  onClickPrev,
  onClickToday,
  onChangeView,
}: ToolbarProps) {
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [viewFilter, setViewFilter] = useState(viewOptions);

  useEffect(() => {
    if (matchDownSM) {
      const filter = viewOptions.filter(
        (item) => item.value !== "dayGridMonth" && item.value !== "timeGridWeek"
      );
      setViewFilter(filter);
    } else {
      setViewFilter(viewOptions);
    }
  }, [matchDownSM]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ pb: "20px" }}
    >
      {/* Date & Today Button */}
      <Stack direction={"row"} spacing="12px" alignItems={"center"}>
        <Button
          variant="outlined"
          onClick={onClickToday}
          color="secondary"
          size={"small"}
          sx={{ height: "36px" }}
        >
          Today
        </Button>
        <IconButton onClick={onClickPrev}>
          <IcArrowLeft />
        </IconButton>
        <IconButton onClick={onClickNext}>
          <IcArrowRight />
        </IconButton>
        <Typography
          sx={{ fontSize: 18, fontWeight: 600, lineHeight: "28px" }}
          color="text.primary"
        >
          {moment(date).format("MMMM")}
          <span style={{ opacity: 0.48 }}> {moment(date).format("yyyy")}</span>
        </Typography>
      </Stack>

      {/* Filter, Toggles, and More */}
      <Stack direction={"row"}>
        <ToggleButtonGroup
          exclusive
          value={view}
          onChange={(e, v) => onChangeView(v)}
        >
          {viewFilter.map((vf) => (
            <ToggleButton value={vf.value}>{vf.label}</ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      {/* <ButtonGroup variant="outlined" aria-label="outlined button group">
        {viewFilter.map((viewOption) => {
          const Icon = viewOption.icon;
          return (
            <Tooltip title={viewOption.label} key={viewOption.value}>
              <Button
                disableElevation
                size={matchDownSM ? "small" : "medium"}
                variant={viewOption.value === view ? "contained" : "outlined"}
                onClick={() => onChangeView(viewOption.value)}
              >
                <Icon style={{ fontSize: "1.3rem" }} />
              </Button>
            </Tooltip>
          );
        })}
      </ButtonGroup> */}
    </Stack>
  );
}
