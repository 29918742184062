import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import ModalProvider from "mui-modal-provider";
import PageSignup from "./pages/signup";
import ThemeManager from "./middleware/ThemeManager";
import AuthenticatedRoute from "./middleware/AuthenticatedRoute";
import PageForgotPassword from "./pages/forgot-password";
import PageUpdatePassword from "./pages/forgot-password/update-password";
import AuthHandler from "./pages/auth";
import PageEmailVerification from "./pages/verify-email";
import PageSignin from "./pages/login";
import PageDashboard from "./pages/dashboard";

// Defines the routes and any middlewares needed for the application to function.
function App() {
  return (
    <RecoilRoot>
      <ThemeManager>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <ModalProvider>
            <Router>
              <Routes>
                {/* <Route element={<PageLanding />} path="/" /> */}
                <Route element={<PageSignin />} path="/login" />
                <Route
                  element={<PageForgotPassword />}
                  path="/forgot-password"
                />
                <Route path="/action" element={<AuthHandler />} />
                <Route
                  element={<PageUpdatePassword />}
                  path="/update-password"
                />
                <Route
                  path="/verify-email"
                  element={<PageEmailVerification />}
                />
                <Route element={<PageSignup />} path="/signup" />
                <Route
                  path="/*"
                  element={
                    <AuthenticatedRoute redirect={"/login"} splash={<></>}>
                      <PageDashboard />
                    </AuthenticatedRoute>
                  }
                />
              </Routes>
            </Router>
          </ModalProvider>
        </SnackbarProvider>
      </ThemeManager>
    </RecoilRoot>
  );
}

export default App;
