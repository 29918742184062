import {
  CssBaseline,
  ThemeProvider,
  alpha,
  createTheme,
  toggleButtonClasses,
  toggleButtonGroupClasses,
} from "@mui/material";
declare module "@mui/material/styles" {
  interface TypographyVariants {
    sectionHeading: React.CSSProperties;
    sectionDescription: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    sectionHeading?: React.CSSProperties;
    sectionDescription?: React.CSSProperties;
  }
}

declare module "@mui/material" {
  interface Color {
    950: string;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    sectionHeading: true;
    sectionDescription: true;
  }
}

// Set up theme colors and breakpoints.
let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "rgba(37, 99, 235, 1)",
    },
    secondary: {
      main: "#3CD7CD",
    },
    background: {
      default: "#F9FAFB",
      paper: "#F9FAFB",
    },
    text: {
      primary: "#020617",
      secondary: "#475467",
    },
    error: {
      main: "#EF4444",
    },
    grey: {
      "50": "#FFF",
      "100": "#f1f5f9",
      "200": "#e2e8f0",
      "300": "#6B7280",
      "400": "#94a3b8",
      "500": "#AEB3C2",
      "600": "#80889E",
      "700": "#334155",
      "800": "#1e293b",
      "900": "#0f172a",
      "950": "#020617",
    },
  },
  typography: {
    fontFamily: "Manrope",
    subtitle1: {
      color: "#475467",
    },
    sectionHeading: {
      color: "#020617",
      fontFamily: "Manrope",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: "0.09px",
    },
    sectionDescription: {
      color: "#6B7280",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.07px",
    },
  },
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1320,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          paddingTop: "2px",
          paddingLeft: "4px",
          paddingRight: "4px",
          paddingBottom: "2px",
          borderRadius: "4px",
          color: "#FFF",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: (vars) => ({
          border: vars.error ? "1px solid #EF4444" : "1px solid #E5E7EB",
          borderRadius: "8px",
          color: "#1F2937",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.07px",
          background: "#FFFFFF",
          ":focus-within": {
            border: "1px solid #2563EB",
            boxShadow: "0px 0px 0px 4px rgba(59, 130, 246, 0.25)",
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          fontWeight: 600,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledError: {
          padding: "16px",
          fontSize: "16px",
          lineHeight: "24px",
          borderRadius: "12px",
          background: "#FEE2E2",
          color: "#B91C1C",
        },
        filledSuccess: {
          background: "#CCFBF1",
          color: "#0F766E",
          padding: "16px",
          fontSize: "16px",
          lineHeight: "24px",
          borderRadius: "12px",
          borderTop: "2px solid #14B8A6",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "20px",
          padding: "14px 16px",
        },
        containedPrimary: {
          background: "rgba(37, 99, 235, 1)",
        },
        containedSecondary: {
          background: "#FFF",
          borderRadius: "8px",
          border: "1px solid #E5E7EB",
          color: "#1F2937",
          ":hover": {
            background: "#E5E7EB",
          },
        },
        sizeSmall: {
          height: "40px",
        },
        outlinedSecondary: {
          borderColor: "#E5E7EB",
          color: "#1F2937",
          ":hover": {
            background: "#E5E7EB",
            borderColor: "#E5E7EB",
          },
        },
        textSecondary: {
          color: "#6B7280",
          fontSize: "14px",
          fontWeight: 500,
          fontStyle: "normal",
          height: "24px",
          ":hover": {
            background: "#0000",
            color: "#1F2937",
          },
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {},
    },
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          background: "#FFF",
          borderRadius: "16px",
          border: "1px solid #E5E7EB",
        },
        elevation1: {
          border: "0.5px solid #E5E7EB",
          background: "#F8FAFC",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "12px",
        },
        elevation2: {
          border: "0.5px solid #E5E7EB",
          background: "#FFF",
          borderRadius: "8px",
          boxShadow: "none",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: "#E5E7EB",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "8px",
          background: "#F8FAFC",
          boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.05)`,
          padding: "2px",
          height: "36px",
          // [`& .${toggleButtonGroupClasses.selected}`]: {
          //   color: theme.palette.primary.main,
          // },
          // ...theme.applyStyles("dark", {
          //   [`& .${toggleButtonGroupClasses.selected}`]: {
          //     color: "#fff",
          //   },
          //   boxShadow: `0 4px 16px ${alpha(theme.palette.primary.main, 0.5)}`,
          // }),
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: "8px 12px",
          textTransform: "none",
          height: "32px",
          borderRadius: "6px",
          border: "1px solid #0000",
          fontSize: 14,
          fontWeight: 500,
          color: theme.palette.grey[400],
          [`&.${toggleButtonClasses.selected}`]: {
            color: theme.palette.text.primary,
            background: "#FFF",
            border: "1px solid #E5E7EB",
            borderRadius: "8px !important",
          },
          // ...theme.applyStyles("dark", {
          //   color: theme.palette.grey[400],
          //   boxShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
          //   [`&.${toggleButtonClasses.selected}`]: {
          //     color: theme.palette.primary.main,
          //   },
          // }),
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "0.5px solid #E5E7EB",
          borderRadius: "12px",
          outline: "none",
          ":before": {
            display: "none",
          },
          ":last-of-type": {
            borderRadius: "12px",
          },
        },
      },
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          fontSize: 14,
          fontWeight: 500,
          color: "#6B7280",
        },
      },
    },
  },
});

export default function ThemeManager(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
