import { GetRtmClient } from ".";
import { Trade } from "../../types/Trade";

export async function RtmConnectAccount(options: {
  server: string;
  password: string;
  login: string;
  platform: string;
}) {
  const _rtm = await GetRtmClient();
  const prof = await _rtm.CallWait<Boolean>("RtmConnectAccount", options);
  return prof;
}

export async function RtmGetTradingJournalStats(
  period: "30-days" | "3-months" | "6-months" | "1-yaer"
) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<{
    chart: {
      growth: { x: number; y: number }[];
      equity: { x: number; y: number }[];
    };
    trades: {
      wins: number;
      losses: number;
      count: number;
      days: number;
      percentByTrades: number;
      percentByDays: number;
      averageWin: number;
      averageLoss: number;
    };
    pnl: number;
  }>("RtmGetTradingJournalStats", {
    period,
    now: new Date().toISOString(),
  });
  return _data;
}

export async function RtmGetTrades(from: number, to: number, account?: string) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<Trade[]>("RtmGetTrades", {
    from,
    to,
    account,
  });
  return _data;
}

export async function RtmGetTrade(id: string) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<Trade>("RtmGetTrade", id);
  return _data;
}
export async function RtmUpdateTrade(trade: Trade) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<boolean>("RtmUpdateTrade", trade);
  return _data;
}

export async function RtmGetLinkPreview(link: string) {
  const _rtm = await GetRtmClient();
  const _data = await _rtm.CallWait<
    false | { title: string; description: string; thumbnail: string }
  >("RtmGetLinkPreview", link);
  return _data;
}
