import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ZInput from "../../../components/ZInput";
import { RtmGetProfile } from "../../../core/rtm";
import { enqueueSnackbar } from "notistack";
import { useRecoilState } from "recoil";
import { aUser } from "../../../core/state";
import { RtmConnectAccount } from "../../../core/rtm/user";
import LoadingOverlay from "../../../components/LoadingOverlay";

export default function PageUserDashboard() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [platform, setPlatform] = useState("mt5");
  const [server, setServer] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);

  const [profile, setProfile] = useRecoilState(aUser);

  const navigate = useNavigate();

  async function connect() {
    try {
      if (!login) {
        setError("Please enter your account login.");
        return;
      }
      if (!password) {
        setError("Please enter your account password.");
        return;
      }
      if (!server) {
        setError("Please enter your server address.");
        return;
      }
      setBusy(true);
      setConnecting(true);
      const _r = await RtmConnectAccount({
        login: login,
        password: password,
        platform: platform,
        server: server,
      });
      if (_r) {
        enqueueSnackbar("Account connected successfully!", {
          variant: "success",
        });
        setConnected(true);
        setConnecting(false);
      } else {
        enqueueSnackbar("Error connecting your account. ", {
          variant: "error",
        });
      }
    } catch (err: any) {
      enqueueSnackbar("Error connecting account: " + err.message || err.name, {
        variant: "error",
      });
      console.error("Error connecting an account. ", err);
    }
    setBusy(false);
    setConnecting(false);
    return false;
  }

  async function load() {
    try {
      setBusy(true);
      const _acc = await RtmGetProfile();
      setProfile(_acc);
      if (_acc?.accounts?.length > 0) {
        setConnected(true);
        setLogin(_acc.accounts[0].login);
        setServer(_acc.accounts[0].server);
        setPlatform(_acc.accounts[0].platform);
        setPassword(_acc.accounts[0].password);
      }
    } catch (err: any) {
      enqueueSnackbar("Error getting profile data. ", { variant: "error" });
      console.error("Error getting profile. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  if (busy || !profile) {
    return <LoadingOverlay />;
  }

  return (
    <Paper elevation={0} sx={{ m: "8px" }}>
      <Stack sx={{ px: "24px", py: "16px" }}>
        <Typography variant="sectionHeading">Dashboard</Typography>
        <Typography variant="sectionDescription"></Typography>
      </Stack>
      <Divider />
      <Stack spacing="32px">
        <Stack>
          <Typography variant="h1" sx={{ fontSize: "46px", fontWeight: "500" }}>
            Connect your account
          </Typography>
          <Typography variant="subtitle1">
            Enter your account details below to connect your trading account
            with QuantifyFx.
          </Typography>
        </Stack>
        <Stack spacing="8px">
          <ZInput
            disabled={connected || busy}
            label="Account Login"
            placeholder="Enter your login"
            text={login}
            onUpdate={(t) => setLogin(t)}
            errorText={error === "login" ? "Enter your account login." : ""}
          />
          <ZInput
            disabled={connected || busy}
            label="Server address"
            placeholder="Enter server address"
            text={server}
            onUpdate={(t) => setServer(t)}
            errorText={error === "server" ? "Enter your server address." : ""}
          />
          <ZInput
            disabled={connected || busy}
            label="Password"
            text={password}
            onUpdate={(t) => setPassword(t)}
            password
            placeholder="Enter your account password"
            errorText={error === "password" ? "Invalid password." : ""}
          />
          <Typography>Choose your account platform</Typography>
          <Stack direction={"row"} spacing={"8px"}>
            <Stack
              onClick={() => setPlatform("mt5")}
              flex={1}
              sx={{
                pointerEvents: connected || busy ? "none" : "auto",
                p: "20px",
                transition: "all .2s",
                background: "#DDD",
                cursor: "pointer",
                border:
                  platform === "mt5"
                    ? "1px solid #48F"
                    : "1px solid transparent",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              }}
            >
              <Typography>MetaTrader 5</Typography>
            </Stack>
            <Stack
              onClick={() => setPlatform("mt4")}
              flex={1}
              sx={{
                pointerEvents: connected || busy ? "none" : "auto",
                p: "20px",
                transition: "all .2s",
                background: "#DDD",
                cursor: "pointer",
                border:
                  platform === "mt4"
                    ? "1px solid #48F"
                    : "1px solid transparent",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              }}
            >
              <Typography>MetaTrader 4</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          disabled={busy || connected}
          onClick={connect}
        >
          {connecting && (
            <>
              <CircularProgress
                variant="indeterminate"
                color="secondary"
                size={"20px"}
              />
            </>
          )}
          {!connecting && !connected && "Connect"}
          {connected && "Connected"}
        </Button>
      </Stack>
    </Paper>
  );
}
