import moment from "moment";
import IcTrendUp from "../../../assets/icons/IcTrendUp";
import Chart from "react-apexcharts";
import { useRef } from "react";
import TradingJournalProgressCard from "../../../components/TradingJournalProgressCard";
import TradingJournalStatCard from "../../../components/TradingJournalStatCard";

import { useState, useEffect } from "react";
import { Divider, Paper, Stack, Typography, useTheme } from "@mui/material";
import Calendar from "../../../components/Calendar";
import { enqueueSnackbar } from "notistack";
import { RtmGetTradingJournalStats } from "../../../core/rtm/user";
import { Trade } from "../../../types/Trade";

export default function SectionTradingJournal() {
  const theme = useTheme();
  const [busy, setBusy] = useState(false);
  const [series, setSeries] = useState<any>([
    {
      name: "pnl",
      data: [
        {
          x: "Jan",
          y: 31,
        },
        {
          x: "Feb",
          y: 40,
        },
        {
          x: "Mar",
          y: 28,
        },
        {
          x: "Apr",
          y: 51,
        },
        {
          x: "May",
          y: 42,
        },
        {
          x: "Jun",
          y: 56,
        },
        {
          x: "Jul",
          y: 33,
        },
      ],
      group: "apexcharts-axis-0",
      zIndex: 0,
    },
    {
      name: "equity",
      group: "apexcharts-axis-0",
      data: [
        {
          x: "Jan",
          y: "50",
        },
        {
          x: "Feb",
          y: "60",
        },
        {
          x: "Mar",
          y: "40",
        },
        {
          x: "Apr",
          y: "40",
        },
        {
          x: "May",
          y: "40",
        },
        {
          x: "Jun",
          y: "50",
        },
        {
          x: "Jul",
          y: "55",
        },
      ],
      zIndex: 1,
    },
  ]);
  const [trades, setTrades] = useState<{
    wins: number;
    losses: number;
    count: number;
    days: number;
    percentByTrades: number;
    percentByDays: number;
    averageWin: number;
    averageLoss: number;
  }>();
  const [pnl, setPnl] = useState(0);

  async function load() {
    try {
      setBusy(true);
      console.log("Loading trades");
      const _data = await RtmGetTradingJournalStats("3-months");
      setSeries([
        {
          name: "Growth",
          data: _data.chart.growth,
          group: "apexcharts-axis-0",
          zIndex: 0,
        },
        {
          name: "Equity",
          data: _data.chart.equity,
          group: "apexcharts-axis-0",
          zIndex: 1,
        },
      ]);
      console.log("Loaded data", _data);
      setTrades(_data.trades);
      setPnl(_data.pnl || 0);
      console.log(_data.trades);
    } catch (err: any) {
      enqueueSnackbar("Error loading stats. Please try later. ", {
        variant: "error",
      });
      console.error("Error loading data for trading journal. ", err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Paper elevation={0} sx={{ m: "8px" }}>
      <Stack sx={{ px: "24px", py: "16px" }}>
        <Typography variant="sectionHeading">Trading Journal</Typography>
        <Typography variant="sectionDescription">
          {moment().format("dddd, MMMM DD, YYYY")}
        </Typography>
      </Stack>
      <Divider />

      {/* Chart */}
      <Stack sx={{ px: "24px", py: "16px" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "28px",
              letterSpacing: "0.09px",
              color: theme.palette.text.primary,
            }}
          >
            Cumulative P&L
          </Typography>
        </Stack>

        <Stack sx={{ py: "16px" }}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 300,
              lineHeight: "32px",
              letterSpacing: "0.12px",
              color: theme.palette.text.primary,
            }}
          >
            ${pnl.toFixed(0)}
          </Typography>
          <Stack direction={"row"} alignItems={"center"}>
            <IcTrendUp />
            <Typography color={theme.palette.success.main}>
              +?.?%{" "}
              <span style={{ color: theme.palette.grey["600"] }}>
                last 30 days
              </span>
            </Typography>
          </Stack>

          <Chart
            type="area"
            series={series}
            width={"100%"}
            height={"324px"}
            options={{
              chart: {
                animations: {
                  enabled: true,
                },
                background: "#0000",
                fontFamily: "Manrope",
                type: "area",
              },
              xaxis: {
                type: "datetime",
              },
              yaxis: {
                decimalsInFloat: 0,
                labels: {
                  formatter(val, opts) {
                    return `$${val}`;
                  },
                },
              },
              tooltip: {
                y: {
                  formatter(val, opts) {
                    return "$" + val.toFixed(0);
                  },
                },
              },
              fill: {
                type: "gradient",
                gradient: {
                  shade: "light",
                  type: "vertical",
                  opacityFrom: 0.6,
                  opacityTo: 0,
                  colorStops: [
                    {
                      opacity: 0.2,
                      offset: 0,
                      color: "#298C6B",
                    },
                    {
                      opacity: 0,
                      offset: 100,
                      color: "#FFFFFF",
                    },
                  ],
                },
              },
              grid: {
                strokeDashArray: 6,
                padding: {
                  right: 24,
                  left: 24,
                },
              },
              legend: {
                showForSingleSeries: true,
                horizontalAlign: "right",
                fontSize: "14px",
                offsetX: 0,
                offsetY: 0,
                markers: {
                  size: 8,
                  fillColors: ["#298C6B", "#BBDAD0"],
                },
              },
              stroke: {
                curve: "straight",
                lineCap: "round",
                width: 2,
                colors: ["#298C6B", "#BBDAD0"],
                fill: {
                  type: "solid",
                  opacity: 0.85,
                  gradient: {
                    shade: "light",
                    type: "vertical",
                    shadeIntensity: 0.5,
                    inverseColors: false,
                    opacityFrom: 0.65,
                    opacityTo: 0.5,
                    stops: [0, 100, 100],
                    colorStops: [],
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
            }}
          />
        </Stack>
      </Stack>

      <Paper elevation={1} sx={{ p: "8px", mx: "24px", my: "12px" }}>
        <Stack direction={"row"} spacing={"8px"}>
          <TradingJournalProgressCard
            heading="Winning % by Trades"
            primaryLabel="Wins"
            primaryValue={trades?.wins || 0}
            secondaryLabel="Losses"
            secondaryValue={trades?.losses || 0}
            value={trades?.percentByTrades + "%"}
            centerHeading={trades?.count.toString() || "0"}
            centerText="Trades"
          />
          <TradingJournalProgressCard
            heading="Winning % by Days"
            primaryLabel="Wins"
            primaryValue={6}
            secondaryLabel="Losses"
            secondaryValue={2}
            value="??"
            centerHeading="8"
            centerText="Trades"
          />
          <Stack flex={1} spacing={"8px"}>
            <Stack
              flex={1}
              direction={"row"}
              sx={{ width: "100%" }}
              spacing={"8px"}
            >
              <TradingJournalStatCard
                heading="Net P&L"
                value={"$" + pnl.toFixed(0)}
              />
              <TradingJournalStatCard
                heading="Total P&L"
                value={"$" + pnl.toFixed(0)}
              />
              <TradingJournalStatCard heading="Profit Factor" value={"??"} />
            </Stack>
            <Stack
              flex={1}
              direction={"row"}
              sx={{ width: "100%" }}
              spacing={"8px"}
            >
              <TradingJournalStatCard
                heading="Average Winning Trade"
                value={"$" + (trades?.averageWin.toFixed(0) || "0")}
              />
              <TradingJournalStatCard
                heading="Average Losing Trade"
                negative
                value={"$-" + (trades?.averageLoss.toFixed(0) || "0")}
              />
            </Stack>
          </Stack>
        </Stack>
      </Paper>

      <Divider />

      <Stack sx={{ p: "24px" }}>
        <Calendar />
      </Stack>
    </Paper>
  );
}
