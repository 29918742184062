import { Stack } from "@mui/material";
import { Route, Routes } from "react-router";
import SidebarUser from "../../components/UserSidebar";
import SectionTradingJournal from "./user/trading-journal";
import PageUserDashboard from "./user";
export default function PageDashboard() {
  return (
    <Stack
      sx={{
        height: "100vh",
        transition: "all .2s",
        opacity: 1,
        minWidth: "100vw",
        overflow: "auto",
      }}
      alignItems={"start"}
      justifyContent={"stretch"}
    >
      <Stack
        sx={{
          maxWidth: "1440px",
          width: "100%",
          flex: 1,
          overflow: "visible",
          position: "relative",
        }}
        direction={"row"}
      >
        {/* Sidebar */}
        <SidebarUser />

        <Stack flex={1} overflow={"hidden"}>
          <Stack sx={{ overflow: "hidden" }}>
            <Routes>
              <Route
                element={<SectionTradingJournal />}
                path="/trading-journal"
              />
              <Route path="/" element={<PageUserDashboard />} />
            </Routes>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
