import { useEffect, useRef, useState } from "react";

// material-ui
import { Theme, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import SpeedDial from "@mui/material/SpeedDial";
import Tooltip from "@mui/material/Tooltip";

// third-party
import FullCalendar from "@fullcalendar/react";
import { EventInput } from "@fullcalendar/common";
import {
  DateSelectArg,
  EventClickArg,
  EventDropArg,
  EventSourceInput,
} from "@fullcalendar/core";
import interactionPlugin, {
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";

// types
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { PopupTransition } from "../mantis/transitions";
import ExperimentalStyled from "./CalendarStyled";
import Toolbar from "./Toolbar";
import { NewsEvent } from "../../types/NewsEvents";
import { enqueueSnackbar } from "notistack";
import { RtmGetEvents } from "../../core/rtm";
import moment from "moment";
import { Chip, Popover, Stack, Typography } from "@mui/material";
import { Trade } from "../../types/Trade";
import { useModal } from "mui-modal-provider";
import NewsEventDialog from "../../dialogs/NewsEvent";
import IcOpenTradeGroup from "../../assets/icons/IcOpenTradeGroup";
import { RtmGetTrades } from "../../core/rtm/user";
import TradeDetailsDialog from "../../dialogs/TradeDetails";

// ==============================|| CALENDAR - MAIN ||============================== //

export default function Calendar() {
  const { showModal } = useModal();
  const theme = useTheme();
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<EventInput>();
  const [calendarView, setCalendarView] = useState<string>();
  const [date, setDate] = useState(new Date());
  const [selectedRange, setSelectedRange] = useState<null | {
    start: Date;
    end: Date;
  }>(null);
  const calendarRef = useRef<FullCalendar>(null);
  const [eventSource, setEventSource] = useState<EventInput[]>([]);
  const [events, setEvents] = useState<NewsEvent[]>([]);
  const [trades, setTrades] = useState<Trade[]>([]);

  // HTML Element on which to show the trade group popup in monthly view.
  const [tradeGroupPopupRef, setTradeGroupPopupRef] = useState<any>(null);
  const [tradeGroupPopupTrades, setTradeGroupPopupTrades] = useState<Trade[]>(
    []
  );

  async function loadEvents() {
    try {
      const _events = await RtmGetEvents({
        sort: { type: "date", value: "desc" },
      });

      setEvents(_events);
    } catch (err: any) {
      console.error("Error loading news events. ", err);
      enqueueSnackbar("Error loading events. ", { variant: "error" });
    }
  }

  async function loadTrades() {
    try {
      if (
        trades.filter(
          (t) =>
            moment(t.time).startOf("month").unix() ===
            moment(date).startOf("month").unix()
        ).length <= 0
      ) {
        const _trades = await RtmGetTrades(
          moment(date).startOf("month").unix(),
          moment(date).endOf("month").unix()
        );
        console.log("Loaded trades: ", _trades);
        setTrades([...trades, ..._trades]);
      }
    } catch (err: any) {
      console.error("Error loading trades for calendar. ", err);
      enqueueSnackbar("Error loading trades. ", { variant: "error" });
    }
  }

  function RenderNewsEvent(event: NewsEvent) {
    return (
      <Stack
        alignItems={"start"}
        onClick={() => {
          const _modal = showModal(NewsEventDialog, {
            onClose() {
              _modal.destroy();
            },
            event: event,
          });
        }}
        sx={{
          overflow: "hidden",
          background: "#F1F5F9",
          border: "0.5px solid #E2E8F0",
          borderRadius: "8px",
          alignSelf: "stretch",
          p: "6px",
          transition: "all .2s",
          cursor: "pointer",
          ":hover": {
            border: "0.5px solid " + theme.palette.primary.main,
          },
        }}
        rowGap={"4px"}
        columnGap={"4px"}
      >
        <Chip
          label={event?.impact}
          size="small"
          sx={{
            background:
              event?.impact === "High"
                ? "#EF4444"
                : event?.impact === "Medium"
                ? "#F97316"
                : "#EAB308",
          }}
        />
        <Typography
          flex={1}
          sx={{ textWrap: "wrap" }}
          color={theme.palette.text.primary}
        >
          {event?.title}
        </Typography>
        <Stack
          flex={1}
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Typography color={"#0206177A"}>{event?.country}</Typography>
          <Typography color={"#0206177A"}>
            {moment.unix(event?.date!).format("hh:mm")}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  // Renders a single trade inside the popup...
  function RenderTrade(trade: Trade) {
    return (
      <Stack
        alignItems={"start"}
        sx={{
          overflow: "hidden",
          background:
            trade.type === "ORDER_TYPE_SELL"
              ? "#FEE2E2"
              : "rgba(41, 140, 107, 0.15)",
          border:
            trade.type === "ORDER_TYPE_SELL"
              ? "0.5px solid #FECACA"
              : "0.5x solid rgba(41, 140, 107, 0.20)",
          borderRadius: "8px",
          alignSelf: "stretch",
          p: "6px",
          cursor: "pointer",
        }}
        onClick={() => {
          const _modal = showModal(TradeDetailsDialog, {
            trade: trade,
            onClose() {
              _modal.destroy();
            },
          });
        }}
        rowGap={"4px"}
        columnGap={"4px"}
      >
        <Stack
          flex={1}
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Typography
            color={theme.palette.text.primary}
            fontSize={12}
            fontWeight={500}
            sx={{
              textTransform: "capitalize",
            }}
          >
            {trade.symbol}
          </Typography>
          <Typography
            sx={{
              color: trade.pnl && trade.pnl > 0 ? "#10B981" : "#EF4444",
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {trade.pnl && trade.pnl > 0 ? "+" : "-"}
            {trade.pnl}
          </Typography>
        </Stack>
        <Typography
          flex={1}
          sx={{
            textWrap: "wrap",
            opacity: 0.48,
            fontSize: 12,
            fontWeight: 400,
          }}
          color={theme.palette.text.primary}
        >
          {moment(trade.time).format("hh:mm")}{" "}
          {trade.doneTime && <>- {moment(trade.doneTime!).format("hh:mm")}</>}
        </Typography>
      </Stack>
    );
  }

  function RenderTradeShort(trade: Trade) {
    return (
      <Stack
        alignItems={"start"}
        sx={{
          overflow: "hidden",
          background:
            trade.type === "ORDER_TYPE_SELL"
              ? "#FEE2E2"
              : "rgba(41, 140, 107, 0.15)",
          border:
            trade.type === "ORDER_TYPE_SELL"
              ? "0.5px solid #FECACA"
              : "0.5x solid rgba(41, 140, 107, 0.20)",
          borderRadius: "8px",
          alignSelf: "stretch",
          p: "6px",
          cursor: "pointer",
        }}
        spacing={"8px"}
        direction={"row"}
        justifyContent={"space-between"}
        onClick={() => {
          const _modal = showModal(TradeDetailsDialog, {
            trade: trade,
            onClose() {
              _modal.destroy();
            },
          });
        }}
      >
        <Typography
          color={theme.palette.text.primary}
          fontSize={12}
          fontWeight={500}
          sx={{
            textTransform: "capitalize",
          }}
        >
          {trade.symbol}
        </Typography>
        <Typography
          sx={{
            color: trade.pnl && trade.pnl > 0 ? "#10B981" : "#EF4444",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {trade.pnl && trade.pnl > 0 ? "+" : "-"}
          {trade.pnl}
        </Typography>
      </Stack>
    );
  }

  // Used in the monthly calendar to render a summary of day's trades.
  function RenderTradeGroup(trades: Trade[]) {
    let _pnl = 0;
    for (let _order of trades) {
      _pnl += _order.pnl;
    }

    return (
      <Stack
        sx={{
          background: _pnl > 0 ? "rgba(41, 140, 107, 0.15)" : "#FEF2F2",
          height: "76px",
          width: "138px",
          p: "6px",
          border: "0.5px solid rgba(41, 140, 107, 0.02)",
          borderRadius: "8px",
        }}
      >
        <Typography sx={{ color: "#020617", fontSize: 14, fontWeight: 500 }}>
          ${_pnl > 0 ? "+" : ""}
          {_pnl.toFixed(1)}
        </Typography>
        <Typography
          sx={{ fontSize: 12, fontWeight: 400, color: "rgba(2, 6, 23, 0.48)" }}
        >
          {trades.length} trades
        </Typography>
        <Stack
          onClick={(e) => {
            setTradeGroupPopupRef(e.currentTarget);
            setTradeGroupPopupTrades(trades);
          }}
          // onMouseLeave={(e) => {
          //   setTradeGroupPopupRef(null);
          //   setTradeGroupPopupTrades([]);
          // }}
          flex={1}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
        >
          <IcOpenTradeGroup />
        </Stack>
      </Stack>
    );
  }

  function RenderWeeklyEvent(eventSrc: any) {
    const _eventId = eventSrc.event.id;
    if (_eventId.startsWith("trade:")) {
      const _id = _eventId.split(":")[1];
      const _order = trades?.find((t) => t.id === _id);
      // If the current view is monthlyView, then we render group of trades
      if (calendarView === "dayGridMonth") {
        const _date = _order?.time;
        const _startPeriod = moment(_date).startOf("day").unix();
        const _endPeriod = moment(_date).endOf("day").unix();
        // Get all trades of that period
        const _trades = trades?.filter(
          (t) =>
            moment(t.time).unix() >= _startPeriod &&
            moment(t.time).unix() < _endPeriod
        );
        return RenderTradeGroup(_trades || []);
      } else {
        const _tradeInfo = trades?.find((e) => e.id === _id);
        return RenderTrade(_tradeInfo!);
      }
    } else if (_eventId.startsWith("event:")) {
      // Render a news event
      const _id = _eventId.split(":")[1];
      const _eventInfo = events.find((e) => e.id === _id);
      return RenderNewsEvent(_eventInfo!);
    }
  }

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = matchDownSM ? "listWeek" : "dayGridMonth";
      calendarApi.changeView(newView);
      setCalendarView(newView);
    }
  }, [matchDownSM]);

  // calendar toolbar events
  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView: string) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);

      setCalendarView(newView);
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  // calendar events
  const handleRangeSelect = (arg: DateSelectArg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    setSelectedRange({ start: arg.start, end: arg.end });
    setModalOpen(true);
  };

  const handleEventSelect = (arg: EventClickArg) => {
    // if (arg?.event?.id) {
    //   const event = events.find((event) => event.id === arg.event.id);
    //   setSelectedEvent(event);
    // }
    // setModalOpen(true);
  };

  const handleEventUpdate = async ({
    event,
  }: EventResizeDoneArg | EventDropArg) => {
    // await updateEvent(event.id, {
    //   allDay: event.allDay,
    //   start: event.start,
    //   end: event.end
    // });
  };

  const modalCallback = (openModal: boolean) => {
    // open/close modal based on dialog state
    if (isModalOpen) {
      setSelectedEvent(undefined);
    }
    setModalOpen(openModal);
  };

  const handleModal = () => {
    if (isModalOpen) {
      setSelectedEvent(undefined);
    }
    setModalOpen(!isModalOpen);
  };

  function eventMouseOver(arg: { el: HTMLElement }) {
    arg.el.classList.add("event-hovered");
  }
  function eventMouseLeave(arg: { el: HTMLElement }) {
    arg.el.classList.remove("event-hovered");
  }

  useEffect(() => {
    const _inputEvents: EventInput[] = [];
    if (calendarView === "timeGridWeek") {
      for (let _ei of events) {
        _inputEvents.push({
          title: _ei.title,
          date: moment.unix(_ei.date).toDate(),
          start: moment.unix(_ei.date).toDate(),
          end: moment.unix(_ei.date).add(1, "hour").toDate(),
          editable: false,
          id: "event:" + _ei.id,
        });
      }
    }
    // in monthly calendar, we only add 1 event of each traded day.

    for (let _trade of trades || []) {
      // Check if input events already has this day
      if (calendarView === "dayGridMonth") {
        const _check = _inputEvents.find(
          (ie) =>
            ie.id?.startsWith("trade:") &&
            moment(ie.date).unix() === moment(_trade.time).startOf("day").unix()
        );
        if (_check) continue;
      }
      _inputEvents.push({
        title: _trade.symbol,
        date: moment(_trade.time).startOf("day").toDate(),
        start: moment(_trade.time).startOf("day").toDate(),
        end: moment(_trade.time).endOf("day").toDate(),
        editable: false,
        id: "trade:" + _trade.id,
      });
    }

    setEventSource(_inputEvents);
  }, [events, trades, calendarView]);

  useEffect(() => {
    loadEvents();
  }, []);

  useEffect(() => {
    loadTrades();
  }, [date]);

  return (
    <ExperimentalStyled>
      <Popover
        onClose={() => {
          setTradeGroupPopupRef(null);
          setTradeGroupPopupTrades([]);
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
              borderRadius: "12px",
              background: "#FFF",
              border: "1px solid #E5E7EB",
              p: "4px",
            },
          },
        }}
        open={Boolean(tradeGroupPopupRef)}
        anchorEl={tradeGroupPopupRef}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Stack spacing={"6px"} sx={{ p: "4px" }}>
          {tradeGroupPopupTrades.map((t) => RenderTradeShort(t))}
        </Stack>
      </Popover>
      <Toolbar
        date={date}
        view={calendarView!}
        onClickNext={handleDateNext}
        onClickPrev={handleDatePrev}
        onClickToday={handleDateToday}
        onChangeView={handleViewChange}
      />

      <FullCalendar
        weekends
        editable
        droppable
        selectable
        events={eventSource as any}
        ref={calendarRef}
        rerenderDelay={10}
        initialDate={date}
        eventContent={RenderWeeklyEvent}
        initialView={calendarView}
        dayMaxEventRows={4}
        eventDisplay="block"
        headerToolbar={false}
        eventMouseEnter={eventMouseOver}
        eventMouseLeave={eventMouseLeave}
        expandRows
        views={{
          dayGridMonth: {
            dayHeaderFormat(arg) {
              return moment(arg.date).format("dddd DD");
            },
            dayMaxEventRows: 3,
          },
          timeGridWeek: {
            allDaySlot: false,
            slotDuration: { minutes: 10 },
            slotLabelInterval: { hour: 1 },
            eventOrderStrict: true,
            eventOverlap: false,
            progressiveEventRendering: true,
            eventOrder: "start",
            slotEventOverlap: true,
            slotLabelFormat: {
              minute: "2-digit",
              hour: "2-digit",
              hour12: false,
            },
            dayHeaderFormat(arg) {
              return moment(arg.date).format("dddd DD");
            },
            eventMaxStack: 2,
            moreLinkClassNames: "weekly-more-link",
          },
        }}
        allDayMaintainDuration
        eventResizableFromStart
        select={handleRangeSelect}
        eventDrop={handleEventUpdate}
        eventClick={handleEventSelect}
        eventResize={handleEventUpdate}
        height={matchDownSM ? "auto" : 720}
        plugins={[
          listPlugin,
          dayGridPlugin,
          timelinePlugin,
          timeGridPlugin,
          interactionPlugin,
        ]}
      />
    </ExperimentalStyled>
  );
}
